import LogoWrapper from "@/components/LogoWrapper";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { CacheProvider } from "@/hooks/useCache";
import useSession from "@/hooks/useSession";
import { get, logout, post, put } from "@/lib/axios_utils";
import SingleExtMsg from "@/pages/SingleExtMsg";
import SampleButton from "@/pages/Test.js";
import {
  Link,
  // import SignUp from "@/pages/SignUp";
  // import VerifyOnLanding from "@/pages/VerifyOnLanding";
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import LoadingAnimation from "../LoadingAnimation";

import { useToast } from "@/hooks/use-toast";
import {
  Check,
  ChevronsUpDown,
  GalleryVerticalEnd,
  Loader,
  Search,
} from "lucide-react";
import React, { useEffect, useState } from "react";

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Label } from "@/components/ui/label";
import { Separator } from "@/components/ui/separator";
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarInput,
  SidebarInset,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarProvider,
  SidebarRail,
  SidebarTrigger,
} from "@/components/ui/sidebar";

//pages ad other components
import VersionPicker from "@/components/VersionPicker"; // Import the new component
import { Dash } from "./Dash";
import { Documents } from "./Documents";
import { ExtMsgs } from "./ExtMsgs";

// Update the routeMap constant
const routeMap = {
  "/": "Dashboard",
  "/dashboard": "Dashboard",
  "/orders": "Orders",
  "/documents": "Documents",
  "/section1/item2": "Item 2",
  "/notifications/fromlink": "Notification Details",
};

// Add this custom hook before the Component function
const useBreadcrumbs = () => {
  const location = useLocation();
  const paths = location.pathname.split("/").filter(x => x);

  const getBreadcrumbName = path => {
    const fullPath = "/" + paths.slice(0, paths.indexOf(path) + 1).join("/");
    return routeMap[fullPath] || path;
  };

  console.log("paths", paths);

  return paths.length > 0
    ? paths.map((path, index) => ({
        name: getBreadcrumbName(path),
        path: "/" + paths.slice(0, index + 1).join("/"),
        isLast: index === paths.length - 1,
      }))
    : [{ name: "Dashboard", path: "/", isLast: true }];
};

const MultiPatientPicker = am_pairs => {
  const [selectedAccountMember, setSelectedAccountMember] = useState(
    am_pairs[0]
  );
  return (
    <SidebarHeader>
      <SidebarMenu>
        <SidebarMenuItem>
          <VersionPicker
            versions={am_pairs}
            selectedVersion={selectedAccountMember}
            setSelectedVersion={setSelectedAccountMember}
          />
        </SidebarMenuItem>
      </SidebarMenu>
      {/* <SidebarGroup className='py-0'>
            <SidebarGroupContent className='relative'>
              <Label htmlFor='search' className='sr-only'>
                Search
              </Label>
              <SidebarInput
                id='search'
                placeholder='Search the docs...'
                className='pl-8'
              />
              <Search className='pointer-events-none absolute left-2 top-1/2 size-4 -translate-y-1/2 select-none opacity-50' />
            </SidebarGroupContent>
          </SidebarGroup> */}
    </SidebarHeader>
  );
};

export default function Component() {
  const navigate = useNavigate();

  // Check if user is already logged in
  const {
    isAuthenticated,
    isLoading: sessionLoading,
    cognitoUser,
    patientInfo,
    alerts,
  } = useSession();
  // useEffect(() => {
  //   if (!isAuthenticated && !sessionLoading) {
  //     navigate("/login");
  //   }
  // }, [isAuthenticated, sessionLoading, navigate]);

  const onLogout = () => {
    logout();
    navigate("/login");
  };

  const { toast } = useToast();

  // Add this with other hooks
  const breadcrumbs = useBreadcrumbs();

  const static_menu = {
    // versions: ["1.0.1", "1.1.0-alpha", "2.0.0-beta1"],
    navMain: [
      {
        title: "Orders",
        route: "/orders",
      },
      {
        title: "Documents",
        route: "/documents",
      },
      {
        title: "Patient Handbook",
        url: "https://www.supercarehealth.com/patient-handbook",
      },
    ],
  };

  if (sessionLoading) {
    return (
      <div className='flex items-center justify-center min-h-screen bg-brand-gray'></div>
    );
  }

  if (!isAuthenticated) {
    navigate("/login");
    return;

    // return (
    //   <LogoWrapper>
    //     <p>
    //       You are not signed in.
    //       <br />
    //       <Link to='/login' className='text-blue-500 hover:underline'>
    //         Sign In
    //       </Link>
    //     </p>
    //   </LogoWrapper>
    // );
  }

  return (
    <SidebarProvider>
      <div className='flex h-screen overflow-hidden w-full'>
        <div className='sticky top-0 h-screen'>
          <Sidebar className='pt-0 h-full'>
            <SidebarContent>
              {/* add logo in it's own sidbar group */}
              {/* <SidebarGroup>
                <SidebarGroupContent>
                  <div className='flex items-center pl-2 h-12'>
                    <img src='/logo.svg' alt='logo' className='h-12' />
                  </div>
                </SidebarGroupContent>
              </SidebarGroup> */}

              <SidebarGroup>
                <SidebarGroupLabel>
                  Supercare Health Patient Portal
                </SidebarGroupLabel>
                <SidebarGroupContent className='space-y-4'>
                  <SidebarMenu>
                    <SidebarMenuItem>
                      <SidebarMenuButton
                        asChild
                        isActive={"/dashboard" === location.pathname}
                      >
                        <Link to={"/dashboard"}>
                          Dashboard
                          {alerts.length > 0 && (
                            <span className='bg-orange-300 text-black rounded-md px-2'>
                              {alerts.length}
                            </span>
                          )}
                        </Link>
                      </SidebarMenuButton>
                    </SidebarMenuItem>
                    {static_menu.navMain.map(item => (
                      <SidebarMenuItem key={item.title}>
                        <SidebarMenuButton
                          asChild
                          isActive={item.route === location.pathname}
                        >
                          {item.route ? (
                            <Link to={item.route}>{item.title}</Link>
                          ) : (
                            <a target={item.title} href={item.url}>
                              {item.title}
                            </a>
                          )}
                        </SidebarMenuButton>
                      </SidebarMenuItem>
                    ))}
                    <SidebarMenuItem className='mt-16 w-1/2 transition-colors duration-200 hover:bg-white hover:border hover:border-black rounded-md'>
                      <SidebarMenuButton
                        asChild
                        // isActive={item.route === location.pathname}
                        onClick={onLogout}
                      >
                        <Button>Sign Out</Button>
                      </SidebarMenuButton>
                    </SidebarMenuItem>
                  </SidebarMenu>
                </SidebarGroupContent>
              </SidebarGroup>

              {/* We create a SidebarGroup for each parent. */}
              {/* {static_menu.navMain.map(item => (
                  <SidebarGroup className='mt-8' key={item.title}>
                    <SidebarGroupLabel>{item.title}</SidebarGroupLabel>
                    <SidebarGroupContent>
                      <SidebarMenu>
                        {item.items.map(item => (
                          <SidebarMenuItem key={item.title}>
                            <SidebarMenuButton
                              asChild
                              // isActive={item.route === location.pathname}
                            >
                              {item.route ? (
                                <Link to={item.route}>{item.title}</Link>
                              ) : (
                                <a target={item.title} href={item.url}>
                                  {item.title}
                                </a>
                              )}
                            </SidebarMenuButton>
                          </SidebarMenuItem>
                        ))}
                      </SidebarMenu>
                    </SidebarGroupContent>
                  </SidebarGroup>
                ))} */}

              {process.env.MAIN_ENV === "TEST" && (
                <SidebarGroup>
                  <SidebarGroupLabel>Dev Only</SidebarGroupLabel>
                  <SidebarGroupContent className='space-y-4'>
                    <SidebarMenu>
                      {cognitoUser.am_pairs.map(am => (
                        <SidebarMenuItem key={am}>
                          <SidebarMenuButton
                            asChild
                            // isActive={item.route === location.pathname}
                          >
                            <a href={am}>Account {am}</a>
                          </SidebarMenuButton>
                        </SidebarMenuItem>
                      ))}
                    </SidebarMenu>
                  </SidebarGroupContent>
                </SidebarGroup>
              )}
            </SidebarContent>
            <SidebarRail />
          </Sidebar>
        </div>

        <div className='flex-1 overflow-auto'>
          <SidebarInset>
            <div className='flex flex-col h-screen'>
              <div className='sticky top-0 z-10 bg-white'>
                <header className='flex shrink-0 items-justify-start gap-2 px-4 pt-2'>
                  <img src='/logo.svg' alt='logo' className='h-12' />
                </header>

                <header className='flex h-12 shrink-0 items-center gap-2 border-b px-4'>
                  <SidebarTrigger className='-ml-1' />
                  <Separator orientation='vertical' className='mr-2 h-4' />
                  <Breadcrumb>
                    <BreadcrumbList>
                      <BreadcrumbItem>
                        <BreadcrumbLink href='/'>
                          Patient Dashboard
                        </BreadcrumbLink>
                      </BreadcrumbItem>
                      <BreadcrumbSeparator />
                      {breadcrumbs.map((crumb, index) => (
                        <React.Fragment key={crumb.path}>
                          <BreadcrumbItem>
                            {crumb.isLast ? (
                              <BreadcrumbPage>{crumb.name}</BreadcrumbPage>
                            ) : (
                              <BreadcrumbLink href={crumb.path}>
                                {crumb.name}
                              </BreadcrumbLink>
                            )}
                          </BreadcrumbItem>
                          {!crumb.isLast && <BreadcrumbSeparator />}
                        </React.Fragment>
                      ))}
                    </BreadcrumbList>
                  </Breadcrumb>
                  <div className='ml-auto px-3'>
                    <div className='flex items-center gap-2 text-sm'>
                      <div className='hidden font-medium text-muted-foreground md:inline-block'>
                        Account # {cognitoUser.accounts[0]}
                      </div>
                    </div>
                  </div>
                </header>
              </div>

              <div className='flex-1 overflow-auto pb-20'>
                <div className='flex flex-1 flex-col gap-4 p-4'>
                  <CacheProvider ttlMinutes={2}>
                    <Routes>
                      <Route
                        path='/'
                        element={
                          <Dash
                            alerts={alerts || []}
                            cognitoUser={cognitoUser}
                            patientInfo={patientInfo}
                          />
                        }
                      />
                      <Route
                        path='/dashboard'
                        element={
                          <Dash
                            alerts={alerts || []}
                            cognitoUser={cognitoUser}
                            patientInfo={patientInfo}
                          />
                        }
                      />

                      <Route
                        path='/orders'
                        element={<ExtMsgs cognitoUser={cognitoUser} />}
                      />
                      <Route path='/documents' element={<Documents />} />
                      <Route
                        path='/section1/item2'
                        element={<h2 className='p-8'>Item 2 Page</h2>}
                      />
                      <Route
                        path='/notifications/fromlink/:url_hash'
                        element={<SingleExtMsg />}
                      />
                    </Routes>
                  </CacheProvider>
                </div>
              </div>
            </div>
          </SidebarInset>
        </div>
      </div>
    </SidebarProvider>
  );
}
