import {
  Alert,
  // AlertCircle,
  AlertDescription,
  AlertTitle,
} from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import useSession from "@/hooks/useSession";
import { get, post } from "@/lib/axios_utils";
import { onGetAobURL } from "@/lib/utils";
import {
  Bell,
  BookOpenText,
  Cable,
  CircleUserRound,
  CreditCard,
  Truck,
} from "lucide-react";
import React, { useEffect } from "react";

import {
  Link,
  // import SignUp from "@/pages/SignUp";
  // import VerifyOnLanding from "@/pages/VerifyOnLanding";
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
} from "react-router-dom";

import { useToast } from "@/hooks/use-toast";

const FEATURES_LIST = [
  {
    title: "Orders",
    description: "View and track your orders",
    enabled: true,
    icon: Truck,
    path: "/orders",
  },
  {
    title: "Personal Information",
    description: "View or update your contact information",
    enabled: false,
    icon: CircleUserRound,
  },
  {
    title: "Records",
    description: "View results, health summaries, and documents",
    enabled: false,
    icon: BookOpenText,
  },
  {
    title: "Notifications",
    description: "Customize notification and communication preferences",
    enabled: false,
    icon: Bell,
  },
  {
    title: "Billing",
    description: "View and pay your bills",
    enabled: false,
    icon: CreditCard,
  },
  {
    title: "Equipment Troubleshoot",
    description: "View troubleshooting options, videos and materials",
    enabled: false,
    icon: Cable,
  },
];

const FeatureBlock = ({ title, description, path, icon: Icon, enabled }) => {
  const navigate = useNavigate();

  const onFeatureClick = path => navigate(path);

  return (
    <div
      onClick={() => enabled && onFeatureClick(path)}
      className={`flex items-start gap-4 p-4 m-2 rounded-lg ${
        enabled
          ? "bg-brand-navy text-brand-white cursor-pointer"
          : "bg-brand-light-blue text-gray-400"
      }`}
    >
      <Icon
        className={`w-8 h-8 mt-1 ${enabled ? "text-white stroke-[2]" : ""}`}
      />
      <div>
        <h3
          className={`text-lg font-semibold ${
            enabled ? "text-white" : "text-brand-muted"
          }  `}
        >
          {title}
        </h3>
        <p className={enabled ? "text-gray-400" : "text-brand-muted"}>
          {description}
        </p>
        {!enabled && (
          <span className='text-brand-muted mt-2 text-sm'>(*Coming Soon)</span>
        )}
      </div>
    </div>
  );
};

const FeatureListing = () => {
  return (
    <div className='grid grid-cols-1 md:grid-cols-3 gap-4 mt-8'>
      {FEATURES_LIST.map((feature, idx) => (
        <FeatureBlock key={idx} {...feature} />
      ))}
    </div>
  );
};

const NoAlertBanner = () => {
  return (
    <Alert key={alert.kind} className='bg-brand-slate'>
      <AlertTitle className='mb-4 text-brand-black flex items-center gap-2'>
        <Bell className='h-6 w-6' />
        No Alerts
      </AlertTitle>
      <AlertDescription>
        All caught up! No alerts to display at this time.
      </AlertDescription>
    </Alert>
  );
};

export const Dash = props => {
  const { alerts, cognitoUser, patientInfo } = props;
  const { toast } = useToast();

  const alertActions = {
    aob_required: onClick => {
      return (
        <div className='mt-4'>
          <Button className='bg-brand-teal text-white' onClick={onClick}>
            Click to Fill Form
          </Button>
        </div>
      );
    },
  };

  return (
    // <div className='bg-gray-100'>
    <div className='w-full px-2 sm:p-8 py-4 space-y-6 bg-white'>
      <div className='max-w-7xl mx-auto px-4'>
        <div>
          <h2 className='text-xl text-brand-black pb-4'>
            Welcome, {patientInfo?.first_name} {patientInfo?.last_name}, to our
            new patient portal!
          </h2>
          <p className='text-brand-muted pb-4'>
            This is just the beginning. More features are coming soon!
          </p>
        </div>
        <div>
          {alerts && alerts.length > 0 ? (
            alerts.map(alert => (
              <Alert key={alert.kind} className='bg-orange-100'>
                {/* <AlertCircle className='h-4 w-4' /> */}

                <AlertTitle className='mb-4 text-red-600 flex items-center gap-2'>
                  <Bell className='h-6 w-6' />
                  {alert.tpl.title}
                </AlertTitle>
                <AlertDescription>
                  {alert.tpl.body}
                  {alertActions[alert.kind] &&
                    alertActions[alert.kind](onGetAobURL)}
                </AlertDescription>
              </Alert>
            ))
          ) : (
            <NoAlertBanner />
          )}
        </div>
        <FeatureListing />
        <div className='mt-8 text-sm text-brand-muted'>
          *These features are currently in development and will be available in
          a future update. Stay tuned!
        </div>
      </div>
    </div>
  );
};
