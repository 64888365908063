import { EmptySection, SessionLoader } from "@/components/SessionFillers";
import TimelinesWrapper from "@/components/TimelinesWrapper";
import {
  Alert,
  // AlertCircle,
  AlertDescription,
  AlertTitle,
} from "@/components/ui/alert";
import { useToast } from "@/hooks/use-toast";
import { useCache } from "@/hooks/useCache";
import { get, post } from "@/lib/axios_utils";
import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";

export const ExtMsgs = props => {
  const { alerts, cognitoUser } = props;
  const { getCacheValue, setCacheValue } = useCache();
  const { toast } = useToast();
  const navigate = useNavigate();
  const [extMsgs, setExtMsgs] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const fetchStarted = useRef(false);

  useEffect(() => {
    if (!fetchStarted.current) {
      fetchStarted.current = true;
      const cachedData = getCacheValue("extMsgs");
      if (cachedData) {
        setExtMsgs(cachedData);
      } else {
        onGetExtMsgs();
      }
    }
  }, []);

  const onGetExtMsgs = async () => {
    setIsLoading(true);
    try {
      const response = await get("/pp2/orders/recent", () => {
        navigate("/login");
      });
      setExtMsgs(response.data.extMsgs);
      setCacheValue("extMsgs", response.data.extMsgs);
    } catch (err) {
      console.error(err);
      toast({
        title: "Error",
        description: "Oops, something went wrong.",
      });
      setExtMsgs([]); // Set empty array on error to prevent retries
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading || !extMsgs) {
    return <SessionLoader />;
  }

  // return (
  //   <div className='w-full px-2 sm:p-8 py-4 space-y-6 bg-white'>
  //     <div className='max-w-7xl mx-auto px-4'></div>
  //   </div>
  // );

  return (
    <div className='w-full px-2 sm:p-8 py-4 space-y-6 bg-white'>
      <div className='max-w-7xl mx-auto px-4'>
        <section className='mb-8 w-full'>
          <h2 className='font-semibold mb-4'>Recent Orders</h2>
          {extMsgs.length > 0 &&
            extMsgs.map(extMsg => (
              <div
                key={extMsg._id}
                className='border border-gray-200 p-2 rounded-lg mb-8'
              >
                <TimelinesWrapper isAuthenticated showDate extMsg={extMsg} />
              </div>
            ))}
          {extMsgs.length === 0 && (
            <div className='border border-gray-200 rounded-lg bg-brand-light-blue'>
              <EmptySection message='No orders to display.' />
            </div>
          )}
        </section>
      </div>
    </div>
  );
};
