import React from "react";

const AOBRequestBlock = ({ onAOBUrlClicked, aobStatusFilled }) => {
  if (aobStatusFilled) {
    return (
      <div className='bg-green-400 p-8 rounded-lg shadow-md w-100'>
        <p className='mt-2 mb-4 font-semibold text-sm text-black'>
          Thank you for submitting the electronic Assignment of Benefits (eAOB)
          form.
        </p>
      </div>
    );
  }

  return (
    <div className='bg-orange-400 p-8 rounded-lg shadow-md w-100'>
      <p className='mt-2 mb-4 font-semibold text-sm text-black'>
        Please complete the electronic Assignment of Benefits (eAOB) form.
      </p>
      <button className='' onClick={onAOBUrlClicked}>
        [Click here to fill eAOB form]
      </button>
    </div>
  );
};

export default AOBRequestBlock;
