import { Button } from "@/components/ui/button";
import Loader from "@/components/ui/Loader";
import useSession from "@/hooks/useSession";
import { logout, storeTokens } from "@/lib/axios_utils";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoadingAnimation from "../LoadingAnimation";

const LoginPage = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showMFAEntry, setShowMFAEntry] = useState(false);
  const [mfaToken, setMfaToken] = useState("");
  const [isConfirmingMFA, setIsConfirmingMFA] = useState(false);
  const [message, setMessage] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [cognitoSession, setCognitoSession] = useState(null);
  const [challengeName, setChallengeName] = useState("");

  const navigate = useNavigate();

  // Check if user is already logged in
  const { isAuthenticated, isLoading: sessionLoading } = useSession();

  const validate_entries = (username, password) => {
    if (!username.trim() || !password.trim()) return false;
    return true;
  };

  const handleRetryLogin = () => {
    setShowMFAEntry(false);
    setMfaToken("");
    setMessage("");
  };

  const handleMFASubmit = async event => {
    event.preventDefault();
    setIsConfirmingMFA(true);
    setMessage("");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER}/api/pp2/login/mfa_confirm`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          mode: "cors",
          body: JSON.stringify({
            username,
            session: cognitoSession,
            challengeName,
            mfaToken,
          }),
        }
      );

      const data = await response.json();

      if (data.error) {
        setMessage(data.error);
        return;
      }

      if (data.tokens) {
        storeTokens(data.tokens);
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      setMessage("Failed to verify MFA code. Please try again.");
      setIsConfirmingMFA(false);
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    setIsSubmitting(true);
    setMessage("...");

    if (!validate_entries(username, password)) {
      setMessage("Please provide username and password.");
      setIsSubmitting(false);
      return;
    }

    fetch(`${process.env.REACT_APP_API_SERVER}/api/pp2/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify({
        username,
        password,
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          throw new Error(data.error);
        }

        // If MFA is required, show the MFA entry form
        if (data.mfa_required) {
          setShowMFAEntry(true);
          setCognitoSession(data.session);
          setChallengeName(data.challengeName);
          setMessage(data.message || "");
          setIsSubmitting(false);
          return;
        }

        storeTokens(data.tokens);
        setMessage(data.message || "Sign in successful...redirecting");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      })
      .catch(error => {
        console.error(error);
        setMessage(error.message);
        setIsSubmitting(false);
      });
  };

  if (isSubmitting || isConfirmingMFA || sessionLoading) {
    return (
      <div className='w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-md'>
        <LoadingAnimation />
      </div>
    );
  }

  if (isAuthenticated) {
    return (
      <div className='w-full max-w-md min-w-[20vw] p-8 space-y-6 bg-white rounded-lg shadow-md'>
        <p>
          You are already logged in.
          <br />
          <Link to='/' className='text-blue-500 hover:underline'>
            Go to Patient Dashboard
          </Link>
          <br />
          or
          <br />
          <a onClick={e => logout()} className='text-blue-500 hover:underline'>
            Logout
          </a>
        </p>
      </div>
    );
  }

  if (showMFAEntry) {
    return (
      <div className='w-full max-w-md min-w-[20vw] p-8 space-y-6 bg-white rounded-lg shadow-md'>
        <h2 className='text-1xl font-bold mb-4'>Enter Verification Code</h2>
        <div className='mb-4'>
          <label
            htmlFor='mfaToken'
            className='block text-sm font-medium text-gray-600 mb-1'
          >
            Verification Code:
          </label>
          <input
            type='text'
            id='mfaToken'
            autoComplete='off'
            disabled={isSubmitting}
            name='mfa_prevent_autofill'
            className='w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500'
            placeholder='Enter code'
            value={mfaToken}
            onChange={e => setMfaToken(e.target.value)}
          />
        </div>

        <Button
          className='w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300 mb-2'
          disabled={isSubmitting}
          onClick={handleMFASubmit}
        >
          Submit Code
        </Button>

        {message && (
          <p className='mt-4 bg-orange-200 p-4 rounded-sm text-xs text-red-600'>
            {message}
          </p>
        )}
        <hr />
        <div className='mt-16'>
          <a
            onClick={handleRetryLogin}
            className='text-sm text-blue-500 hover:underline cursor-pointer'
          >
            Back to Login Form
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className='w-full max-w-md min-w-[20vw] p-8 space-y-6 bg-white rounded-lg shadow-md'>
      <h2 className='text-2xl font-bold'>Sign In</h2>
      <h2 className='text-1xl mb-4'>
        Access to this application is available only to Supercare Health
        patients.
      </h2>

      <form onSubmit={handleSubmit} className='space-y-4'>
        <div>
          <label className='block text-sm font-medium text-gray-700'>
            Email Address
          </label>
          <input
            type='text'
            autoComplete='off'
            name='username_prevent_autofill'
            value={username}
            onChange={e => setUsername(e.target.value)}
            // onKeyUp={handleKeyPress}
            disabled={isSubmitting}
            className='w-full p-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed'
            placeholder=''
          />
        </div>
        <div>
          <label className='block text-sm font-medium text-gray-700'>
            Password
          </label>
          <input
            type='password'
            value={password}
            onChange={e => setPassword(e.target.value)}
            // onKeyUp={handleKeyPress}
            disabled={isSubmitting}
            className='w-full p-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 disabled:bg-gray-100 disabled:cursor-not-allowed'
            placeholder=''
          />
        </div>
        <button
          // onClick={handleSubmit}
          type='submit'
          disabled={isSubmitting}
          className='w-full py-2 mt-4 text-white bg-blue-500 rounded-md hover:bg-blue-600 disabled:bg-blue-300 disabled:cursor-not-allowed'
        >
          {isSubmitting ? (
            <span className='flex items-center justify-center gap-2'>
              <Loader />
              Logging in...
            </span>
          ) : (
            "Login"
          )}
        </button>

        {message && (
          <p className='mt-4 bg-orange-200 p-4 rounded-sm text-xs text-red-600'>
            {message}
          </p>
        )}
      </form>

      <hr />

      <div className='mt-4 '>
        <Link to='/fp' className='text-sm text-blue-500 hover:underline'>
          Forgot Password?
        </Link>
      </div>
      <div className='mt-4'>
        <span className='text-sm text-gray-600'>Haven't registered? </span>
        <Link to='/onboard' className='text-sm text-blue-500 hover:underline'>
          Click here to Register
        </Link>
      </div>
    </div>
  );
};

export default LoginPage;
