import useSession from "@/hooks/useSession";
import { get, post } from "@/lib/axios_utils";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingAnimation from "../LoadingAnimation";
import AOBRequestBlock from "../components/AOBRequestBlock";
import TimelinesWrapper from "../components/TimelinesWrapper";

function SingleExtMsg() {
  const { url_hash } = useParams();
  const [isFetching, setIsFetching] = useState(true);
  const [extMsg, setExtMsg] = useState(null);
  const [urlToAOB, setUrlToAOB] = useState(null);
  const [message, setMessage] = useState("");

  const { account, sessionLoading, getSession, clearSession, isAuthenticated } =
    useSession();

  const fetchExtMsg = async () => {
    setIsFetching(true);
    let path = "/pp2/session/ext_msg" + (url_hash ? `/${url_hash}` : "");

    try {
      let response = await get(path);
      setExtMsg(response.data.extMsg);
    } catch (error) {
      console.error(error);
      setExtMsg(false);
    }
    setIsFetching(false);
  };

  useEffect(() => {
    if (null === extMsg) {
      fetchExtMsg();
    }
  }, [extMsg]);

  const onAOBUrlClicked = () => {
    //parse the url by "/" to get the jotform formId and prefilledUrlId
    let parts = urlToAOB.split("/");
    let formId = parts[parts.length - 3];
    let prefilledUrlId = parts[parts.length - 1];

    console.log({
      formId,
      prefilledUrlId,
    });

    //open the url in a new tab
    window.open(urlToAOB, "_blank");

    setTimeout(
      () => checkAOBStatus(formId, prefilledUrlId),
      CHECK_AOB_FREQUENCY
    );
  };

  // const checkhashCode = () => {
  //   // console.log("checking referral code");
  //   hashCodeChecked.current = true;
  //   let sh = getSession();
  //   let payload = { h: url_hash };
  //   if (sh) {
  //     payload.sh = sh;
  //   }
  //   fetch(`${process.env.REACT_APP_API_SERVER}/api/pp2/notif`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     mode: "cors",
  //     body: JSON.stringify(payload),
  //   })
  //     .then(response => response.json())
  //     .then(data => {
  //       if (data.error) {
  //         throw data.error;
  //       }
  //       if (data.success === false) {
  //         throw data.error;
  //       }
  //       if (data.success === true) {
  //         setIsDoingLandingChecks(false);
  //         setHashCodeValid(true);

  //         if (data.extMsg) {
  //           setDidJustVerifyMMDD(true);
  //           setExtMsg(data.extMsg);
  //           setAccount(data.extMsg.account);
  //         }

  //         if (data.aob_prefilled_url) {
  //           setUrlToAOB(data.aob_prefilled_url);
  //         }
  //       }
  //     })
  //     .catch(error => {
  //       console.error(error);
  //       setMessage(error.message);
  //       setHashCodeValid(false);
  //       setIsDoingLandingChecks(false);
  //       //clear local storage referral code so that next attempt requires MMDD again
  //       window.localStorage.removeItem(LOCAL_STORAGE_KEY);
  //     });
  // };

  const checkAOBStatus = (jotformFormId, jotformPrefilledUrlId) => {
    // if (aobStatusCheckCount >= CHECK_AOB_MAX) {
    //   return;
    // }

    let payload = { account, jotformFormId, jotformPrefilledUrlId };
    let sh = getSession();
    if (sh) {
      payload.sh = sh;
    } else {
      return;
    }

    setAOBStatusCheckCount(aobStatusCheckCount + 1);
    fetch(
      `${process.env.REACT_APP_API_SERVER}/api/pp2/jotform_submissions/aob_status_check`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify(payload),
      }
    )
      .then(response => response.json())
      .then(data => {
        if (data.success === true) {
          //update the aob message
          setAOBStatusFilled(true);
          return;
        }

        if (data.error) {
          return; //do nothing
        }

        if (data.success === false) {
          //keep checking
          // setTimeout(
          //   () => checkAOBStatus(jotformFormId, jotformPrefilledUrlId),
          //   CHECK_AOB_FREQUENCY
          // );
          return;
        }
      })
      .catch(error => {
        console.error(error);
        return;
      });
  };

  if (isFetching || sessionLoading) {
    return (
      <div className='flex items-center justify-center min-h-screen bg-gray-100'>
        <LoadingAnimation />
      </div>
    );
  }

  if (false === extMsg) {
    return (
      <div className='flex items-center justify-center min-h-screen bg-gray-100'>
        <div className='w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-md'>
          <p className='mt-2 text-center text-sm text-gray-600'>
            Something went wrong.
            <br />
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className='flex justify-center min-h-screen bg-gray-100'>
      <div className='p-8 space-y-6 bg-white rounded-lg shadow-md'>
        {urlToAOB && (
          <AOBRequestBlock
            onAOBUrlClicked={onAOBUrlClicked}
            aobStatusFilled={aobStatusFilled}
          />
        )}

        <TimelinesWrapper isAuthenticated extMsg={extMsg} />
        {message && (
          <div className='bg-red-400 p-8 rounded-lg shadow-md w-100'>
            <p className='mt-2 text-sm text-black'>{message}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default SingleExtMsg;
