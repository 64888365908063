import React from "react";
const Footer = () => {
  return (
    <footer className='fixed bottom-0 w-full py-4 px-4 bg-brand-navy text-white z-50'>
      <div className='max-w-7xl mx-auto flex justify-between items-center text-xs text-brand-silver'>
        <span>
          © 2025 SuperCare Health. All Rights Reserved. V
          {process.env.REACT_APP_VER}{" "}
        </span>
        <div className='flex items-center gap-4'>
          <a href='https://supercarehealth.com/privacy-policy/'>
            Privacy Policy
          </a>
          <span className='text-brand-silver'>•</span>
          <a href='https://supercarehealth.com/terms-of-service/'>
            Terms of Service
          </a>
          <span className='text-brand-silver'>•</span>
          <a href='https://supercarehealth.com/terms-and-conditions/'>
            Terms and Conditions
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
