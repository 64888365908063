// Generate a react compnoent that renders a spinning loader. Call it Loader and export it as the default export.
import React from "react";

export default function Loader() {
  return (
    <div className='flex justify-center items-center h-24'>
      <div className='animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900'></div>
    </div>
  );
}
