import React from "react";

const OrderStatusTimeline = ({ timeline }) => {
  const { steps } = timeline;

  return (
    <div className='w-full max-w-4xl mx-auto p-4 mt-4'>
      <div className='hidden md:block'>
        <div className='relative'>
          {/* Circles and Labels */}
          <div className='flex items-start justify-between'>
            {steps.map((step, index) => (
              <div
                key={index}
                className='flex flex-col items-center w-1/6 first:pl-4 last:pr-4'
              >
                <div className='w-8 h-8 mb-2 relative'>
                  {/* Horizontal line segment */}
                  {index < steps.length - 2 && (
                    <div className='absolute left-1/2 top-1/2 w-[calc(450%+5rem)] h-0.5 bg-black -translate-y-1/2'></div>
                  )}

                  {index === steps.length - 1 && (
                    <div className='absolute left-1/2 top-1/2 w-[calc(450%+5rem)] h-0.5 bg-black -translate-y-1/2 -translate-x-60'></div>
                  )}

                  {/* Circle */}
                  <div
                    className={`w-8 h-8 rounded-full border-2 relative z-10 ${
                      step.active
                        ? "border-[#0057B8] bg-[#0057B8]"
                        : index < steps.findIndex(s => s.active)
                        ? "border-black bg-black"
                        : "border-black bg-white"
                    }`}
                  ></div>
                </div>
                <div
                  className={`text-center ${
                    step.active
                      ? "font-bold text-sm text-[#0057B8]"
                      : "text-xs text-black"
                  } w-full px-1`}
                >
                  {step.label}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Vertical view */}
      <div className='md:hidden'>
        <div className='relative pl-4'>
          {steps.map((step, index) => (
            <div key={index} className='flex items-center mb-8 last:mb-0'>
              <div className='relative'>
                {/* Vertical line segment */}
                {index < steps.length - 1 && (
                  <div className='absolute left-1/2 top-4 h-[calc(200%+1rem)] w-0.5 bg-black -translate-x-1/2'></div>
                )}
                {/* Circle */}
                <div
                  className={`w-8 h-8 rounded-full border-2 relative z-10 ${
                    step.active
                      ? "border-[#0057B8] bg-[#0057B8]"
                      : index < steps.findIndex(s => s.active)
                      ? "border-black bg-black"
                      : "border-black bg-white"
                  }`}
                ></div>
              </div>
              {/* Label */}
              <div
                className={`ml-4 ${
                  step.active
                    ? "font-bold text-sm text-[#0057B8]"
                    : "text-xs text-black"
                }`}
              >
                {step.label}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OrderStatusTimeline;
