import { get } from "@/lib/axios_utils";
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export const validate_date = (month, day) => {
  //month must resolve to an integer between 1 and 12 inclusive, day must resolve to an integer between 1 and 31 inclusive
  const parsedMonth = parseInt(month, 10);
  const parsedDay = parseInt(day, 10);
  if (isNaN(parsedMonth) || isNaN(parsedDay)) {
    return false;
  }

  if (
    parsedMonth >= 1 &&
    parsedMonth <= 12 &&
    parsedDay >= 1 &&
    parsedDay <= 31
  ) {
    // Valid date
    return true;
  } else {
    // Invalid date
    return false;
  }
};

const LOCAL_STORAGE_KEY = process.env.REACT_APP_LS_PREFIX + "_notif_rc";
const CHECK_AOB_FREQUENCY = 5000;
const CHECK_AOB_MAX = 30;

export const storeLandingInfo = sh => {
  console.log({ msg: "storing session in ", LOCAL_STORAGE_KEY, sh });
  window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(sh));
};

export const getLandingInfo = () => {
  let sh = window.localStorage.getItem(LOCAL_STORAGE_KEY);
  if (sh === null) return null;
  let parsed;
  try {
    parsed = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY));
  } catch (e) {
    return null;
  }

  //check if the session is expired
  if (parsed && parsed.e && new Date(parsed.e) < new Date()) {
    console.log("session expired");
    window.localStorage.removeItem(LOCAL_STORAGE_KEY);
    return null;
  }

  return JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY));
};

export const clearLandingInfo = (msg, setMsg) => {
  //force clear session and take user back to MMDD verification
  window.localStorage.removeItem(LOCAL_STORAGE_KEY);
  if (msg && setMsg) {
    setMessage(msg);
  }

  setTimeout(() => {
    window.location.reload();
  }, 3000);
};

export const normalizeToPhoneNumberFormat = phoneNumber => {
  /* strip off non numeric, if result is 11 numbers, strip off first number if it's a 1. If it's not a 1, it's invalid, return null */
  let normalized = phoneNumber.toString().replace(/[^0-9]/g, "");
  if (normalized.length === 11 && normalized[0] === "1") {
    normalized = normalized.substr(1);
  } else if (normalized.length !== 10) {
    return null;
  }
  return normalized; //10 digit (no leading 1) or null
};

export const validatePhoneNumber = phoneNumber => {
  if (normalizeToPhoneNumberFormat(phoneNumber) === null) {
    return false;
  }
  return true;
};

export const validateEmailAddress = email => {
  if (!email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)) {
    return false;
  }
  return true;
};

export const validatePassword = password => {
  const minLength = 8;
  const hasNumber = /[0-9]/.test(password);
  const hasSpecial = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);

  return (
    password.length >= minLength &&
    hasNumber &&
    hasSpecial &&
    hasUpperCase &&
    hasLowerCase
  );
};

export const onGetAobURL = async toast => {
  try {
    const response = await get("/pp2/aob/get_link");
    window.open(response.data.url, "aob_form");
  } catch (err) {
    console.error(err);
    toast({
      title: "Error",
      description: "Oops, something went wrong.",
    });
  }
};

export const handleDownloadPOD = async (pod_sub_id, toast) => {
  try {
    const response = await get(`/pp2/pod/${pod_sub_id}`);
    window.open(response.data.url, "pod_" + pod_sub_id);
  } catch (err) {
    console.error(err);
    toast({
      title: "Error",
      description: "Oops, something went wrong.",
    });
  }
};
