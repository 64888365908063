import { EmptySection, SessionLoader } from "@/components/SessionFillers";
import { Button } from "@/components/ui/button";
import Loader from "@/components/ui/Loader";
import { useToast } from "@/hooks/use-toast";
import { useCache } from "@/hooks/useCache";
import { get } from "@/lib/axios_utils";
import { handleDownloadPOD, onGetAobURL } from "@/lib/utils";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

export const Documents = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { getCacheValue, setCacheValue } = useCache();
  const [documents, setDocuments] = useState(null);
  const [extMsgs, setExtMsgs] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [downloadingDoc, setDownloadingDoc] = useState(null);
  const fetchStarted = useRef(false);

  useEffect(() => {
    if (!fetchStarted.current) {
      fetchStarted.current = true;
      setIsLoading(true);
      const cachedDocuments = getCacheValue("documents");
      if (cachedDocuments) {
        setDocuments(cachedDocuments);
      } else {
        fetchDocuments();
      }

      //check if cache has extMsgs
      const cachedExtMsgs = getCacheValue("extMsgs");

      if (cachedExtMsgs) {
        setExtMsgs(cachedExtMsgs);
      } else {
        fetchExtMsgs();
      }

      setIsLoading(false);
    }
  }, []);

  const fetchExtMsgs = async () => {
    try {
      const response = await get("/pp2/orders/recent", () => {
        navigate("/login");
      });
      setExtMsgs(response.data.extMsgs);
      setCacheValue("extMsgs", response.data.extMsgs);
    } catch (err) {
      console.error(err);
      toast({
        title: "Error",
        description: "Oops, something went wrong.",
      });
      setExtMsgs([]); // Set empty array on error to prevent retries
    }
  };

  const fetchDocuments = async () => {
    try {
      const response = await get("/pp2/documents/recent", () => {
        navigate("/login");
      });
      setDocuments(response.data);
      setCacheValue("documents", response.data);
    } catch (err) {
      console.error(err);
      toast({
        title: "Error",
        description: "Failed to load documents.",
      });
      setDocuments([]); // Set empty array on error to prevent retries
    }
  };

  const alertActions = {
    aob_required: () => {
      return (
        <div className='mt-4'>
          <Button className='bg-brand-teal text-white' onClick={onGetAobURL}>
            Click to Fill Form
          </Button>
        </div>
      );
    },
  };

  const downloadCompletedDoc = async (formId, submissionId) => {
    let path = `/pp2/jotform/submissions/${formId}/${submissionId}/pdf`;
    setDownloadingDoc(`${formId}-${submissionId}`);
    try {
      let response = await get(path);
      //response should contain  signedUrl, open in new tab
      window.open(response.data.signedUrl, submissionId);
      toast({
        title: "Success",
        description: "Document downloaded successfully.",
      });
    } catch (err) {
      console.error(err);
      toast({
        title: "Error",
        description: "Failed to download document.",
      });
    } finally {
      setDownloadingDoc(null);
    }
  };

  if (isLoading || !documents || !extMsgs) {
    return <SessionLoader />;
  }

  let extMsgsWithPODs = extMsgs.filter(e => e.pod_sub_id);

  return (
    <div className='w-full px-2 sm:p-8 py-4 space-y-6 bg-white'>
      <div className='max-w-7xl mx-auto px-4 sm:min-w-[800px]'>
        <section className='mb-8'>
          <h2 className='font-semibold mb-4'>Documents to Fill Out or Sign</h2>
          <div
            className={`border border-gray-200 rounded-lg ${
              documents.docs_to_fill?.length && "bg-orange-100"
            }`}
          >
            {documents.docs_to_fill?.length > 0 ? (
              documents.docs_to_fill.map((doc, index) => (
                <div key={index} className='p-4 border-b last:border-b-0'>
                  {doc.title}
                  {doc.url ? (
                    <div className='mt-4'>
                      <Button
                        className='bg-brand-teal text-white'
                        onClick={() => window.open(doc.url, "aob_form")}
                      >
                        Click to Fill Form
                      </Button>
                    </div>
                  ) : (
                    alertActions[doc.kind] &&
                    alertActions[doc.kind](onGetAobURL)
                  )}
                </div>
              ))
            ) : (
              <EmptySection message='No documents to complete' />
            )}
          </div>
        </section>

        <section className='mb-8'>
          <h2 className='font-semibold mb-4'>Completed Documents</h2>

          <div
            className={`border border-gray-200 rounded-lg ${
              documents.completed_docs?.length > 0 && "bg-brand-light-blue"
            }`}
          >
            {documents.completed_docs?.length > 0 ? (
              documents.completed_docs.map((doc, index) => (
                <div key={index} className='p-4 border-b last:border-b-0'>
                  <div>{doc.title}</div>
                  <div className='text-sm text-gray-500'>
                    Submitted {doc.ts}
                  </div>
                  <div className='mt-4 flex items-center gap-2'>
                    <Button
                      className='bg-brand-teal text-white'
                      onClick={() =>
                        downloadCompletedDoc(
                          doc.jotform_formId,
                          doc.lastSubmissionId
                        )
                      }
                      disabled={
                        downloadingDoc ===
                        `${doc.jotform_formId}-${doc.lastSubmissionId}`
                      }
                    >
                      Download Completed Document
                    </Button>
                    {downloadingDoc ===
                      `${doc.jotform_formId}-${doc.lastSubmissionId}` && (
                      <Loader />
                    )}
                  </div>
                </div>
              ))
            ) : (
              <EmptySection message='No completed documents' />
            )}
          </div>
        </section>

        <section className='mb-8'>
          <h2 className='font-semibold mb-4'>Delivery Receipts</h2>

          <div
            className={`border border-gray-200 rounded-lg ${
              extMsgsWithPODs.length > 0 && "bg-brand-light-blue"
            }`}
          >
            {extMsgsWithPODs.length > 0 ? (
              extMsgsWithPODs.map((doc, index) => {
                let title =
                  doc.key === "wos"
                    ? `Work Order W${doc.wos_id}`
                    : `Workunit V${doc.v_id}`;

                return (
                  <div
                    key={index}
                    className='p-4 border-b border-white pb-8 last:border-b-0'
                  >
                    <div className='grid grid-cols-2 sm:grid-cols-3 items-start gap-4'>
                      <div>
                        <span className='text-sm text-gray-600'>{title}</span>
                      </div>
                      <div>
                        <span className='text-xs text-gray-600'>
                          {moment(doc.sqs_ts).format("MMM D, YYYY")}{" "}
                        </span>
                        {/* <span className='text-sm text-gray-600'>
                          Account No. {doc.account}{" "}
                        </span> */}
                      </div>
                      <button
                        className='col-span-2 sm:col-span-1 bg-brand-btn text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300 mt-2 sm:mt-0'
                        onClick={() => {
                          handleDownloadPOD(doc.pod_sub_id, toast);
                        }}
                      >
                        Download Delivery Receipt (PDF)
                      </button>
                    </div>
                  </div>
                );
              })
            ) : (
              <EmptySection message='No delivery receipts' />
            )}
          </div>
        </section>
      </div>
    </div>
  );
};
