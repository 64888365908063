import Footer from "@/components/Footer";
import LogoWrapper from "@/components/LogoWrapper";
import ConsentLanding from "@/pages/ConsentLanding";
import ForgetPassword from "@/pages/ForgetPassword.js";
import Home from "@/pages/Home";
import Login from "@/pages/Login";
import NotifLanding from "@/pages/NotifLanding";
import SelfOnboard from "@/pages/SelfOnboard.js";
import Unsub from "@/pages/Unsub.js";
import UnsubFromOrdStat from "@/pages/UnsubFromOrdStat.js";
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Toaster } from "./components/ui/toaster.jsx";

const App = () => {
  return (
    <Router>
      <div className='min-h-screen flex flex-col pb-[68px]'>
        <div className='flex-grow px-4 sm:px-6 lg:px-8 py-6'>
          <Routes>
            <Route
              path='/signin/:mode/:referral_code'
              element={
                <LogoWrapper>
                  <ConsentLanding />
                </LogoWrapper>
              }
            />

            {/* UNSUBSCRIBE ON LANDING */}
            <Route path='/u/:mode/:unsub_code' element={<Unsub />} />
            <Route
              path='/sim/unsub/:unsub_code'
              element={<UnsubFromOrdStat />}
            />

            <Route
              path='/t/:url_hash'
              element={
                <LogoWrapper>
                  <NotifLanding />
                </LogoWrapper>
              }
            />
            <Route
              path='/sim/t/:url_hash'
              element={
                <LogoWrapper>
                  <NotifLanding />
                </LogoWrapper>
              }
            />

            {/* PATIENT PORTAL  */}
            <Route
              path='/onboard'
              element={
                <LogoWrapper>
                  <SelfOnboard />
                </LogoWrapper>
              }
            />
            <Route
              path='/login'
              element={
                <LogoWrapper>
                  <Login />
                </LogoWrapper>
              }
            />
            <Route
              path='/fp'
              element={
                <LogoWrapper>
                  <ForgetPassword />
                </LogoWrapper>
              }
            />
            <Route path='/*' element={<Home />} />
          </Routes>
        </div>
        <Footer />
        <Toaster />
      </div>
    </Router>
  );
};
export default App;
