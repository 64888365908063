import { useToast } from "@/hooks/use-toast";
import { get } from "@/lib/axios_utils";
import { handleDownloadPOD } from "@/lib/utils";
import moment from "moment";
import React from "react";
import OrderStatusTimeline from "./OrderStatusTimeline";

const gen_tracking_url = (carrier, tracking) => {
  if (carrier === undefined || carrier === null) {
    carrier = "GSO";
  }
  if (carrier.match(/fed.*ex/i)) {
    carrier = "FEDEX";
  } else if (carrier.match(/ups/i)) {
    carrier = "UPS";
  }

  switch (carrier) {
    case "UPS":
      return `https://www.ups.com/track?loc=en_US&tracknum=${tracking}`;
    case "FEDEX":
      return `https://www.fedex.com/apps/fedextrack/?tracknumbers=${tracking}`;
    case "GSO":
      return `https://www.gso.com/Tracking`;
    default:
      return "#";
  }
};

const TimelinesWrapper = ({ isAuthenticated, extMsg, showDate }) => {
  const { wos_data = null, timelinePkg, order_type, shipments = [] } = extMsg;
  const { toast } = useToast();

  let title =
    extMsg.key === "wos"
      ? `Work Order W${extMsg.wos_id}`
      : `Workunit V${extMsg.v_id}`;

  const show_shipment_lines = shipments.length > 0;

  return (
    <div className='flex flex-col items-center pt-4'>
      <div className='flex flex-col mb-8 w-full max-w-[80vw]'>
        <div className='px-2 py-2 rounded-lg'>
          <span className='font-semibold text-sm text-gray-600'>{title}</span>
          <div className='text-sm text-brand-black'>
            Account No. {extMsg.account}{" "}
          </div>

          {showDate && (
            <div className='text-xs text-gray-400'>
              As of {moment(extMsg.sqs_ts).format("ddd MMM D, YYYY hh:mm A")}{" "}
              (Local Time)
            </div>
          )}
          <OrderStatusTimeline timeline={timelinePkg[order_type]} />
          {/* ...rest of the component implementation... */}
          {wos_data && wos_data.lines && wos_data.lines.length > 0 && (
            <div className='mt-6'>
              <h3 className='text-gray-600 font-semibold mb-2'>
                Contents of Order:
              </h3>
              <ol className='list-decimal pl-4 mb-10'>
                {wos_data.lines.map((line, lineIndex) => (
                  <li key={lineIndex} className='text-sm mb-3'>
                    <span className='text-orange-700 mr-2'>
                      QTY {line.qty_ordered} {line.uom}
                    </span>
                    {line.product_desc || line.item} {line.wo_type_str}
                  </li>
                ))}
              </ol>
            </div>
          )}
          {shipments.length > 0 && (
            <div className='mt-6'>
              <h3 className='text-gray-600 font-semibold mb-2'>Shipments:</h3>
              <ul className=''>
                {shipments.map((s, si) => {
                  let url = gen_tracking_url(s.c, s.tn);
                  return (
                    <li key={si} className='text-sm mb-3'>
                      {s.c} {s.tn} <br />
                      <a
                        href={url}
                        className='text-blue-600'
                        target={s.tn.replace(/ /g, "")}
                      >
                        {url}
                      </a>
                      {show_shipment_lines && (
                        <ol className='list-decimal ml-4 mt-2'>
                          {s.lines.map((line, lineIndex) => (
                            <li key={lineIndex} className='text-sm mb-3'>
                              <span className='text-orange-700 mr-2'>
                                QTY {line.qty || line.qty_ordered} {line.uom}
                              </span>
                              {line.product_desc || line.item}
                            </li>
                          ))}
                        </ol>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          <p className='mt-10'>
            Note: The order information shown here is an approximation of your
            order status and order contents and may not include all details
            about your order.
          </p>

          {extMsg.pod_sub_id && (
            <div className='pt-8'>
              <hr />
              <button
                className='bg-blue-500 text-white mt-4 py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300'
                onClick={() => {
                  handleDownloadPOD(extMsg.pod_sub_id, toast);
                }}
              >
                Download Delivery Receipt (PDF)
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TimelinesWrapper;
