import axios from "axios";
const API_BASE = `${process.env.REACT_APP_API_SERVER}/api`;

export const clearTokens = () => {
  window.localStorage.removeItem("tokens");
};

export const getTokens = () => {
  const _tokens = window.localStorage.getItem("tokens");
  if (!_tokens) {
    return null;
  }

  try {
    const tokens = JSON.parse(_tokens);
    return tokens;
  } catch (e) {
    return null;
  }
};

// Helper to get the auth header
const getAuthHeader = onNoAuth => {
  const handleNoAuth = () => {
    clearTokens();
    if (onNoAuth) {
      onNoAuth();
      return;
    }
    return { error: new Error("Invalid tokens") };
  };

  const _tokens = window.localStorage.getItem("tokens");
  if (!_tokens) {
    return handleNoAuth();
  }

  try {
    const tokens = JSON.parse(_tokens);
    if (tokens.expiresAt < new Date().getTime()) {
      return handleNoAuth();
    }

    return {
      Authorization: `Bearer ${tokens.accessToken}`,
      "ngrok-skip-browser-warning": "true",
    };
  } catch (error) {
    return handleNoAuth();
  }
};

// Wrapper for authenticated GET requests
export const get = async (path, onNoAuth = null) => {
  let h = getAuthHeader(onNoAuth);

  try {
    const response = await axios.get(`${API_BASE}${path}`, {
      headers: h,
      mode: "cors",
      credentials: "include",
    });
    return response;
  } catch (error) {
    // If token is invalid/expired, you might want to handle tsession_token here
    if (error.response?.status === 401) {
      clearTokens();
      if (onNoAuth) {
        onNoAuth();
      }
    }
    throw error;
  }
};

// Wrapper for authenticated PUT requests
export const put = async (path, payload, onNoAuth = null) => {
  let h = getAuthHeader(onNoAuth);
  try {
    const response = await axios.put(`${API_BASE}${path}`, payload, {
      headers: h,
      mode: "cors",
      credentials: "include",
    });
    return response;
  } catch (error) {
    if (error.response?.status === 401) {
      clearTokens();
      if (onNoAuth) {
        onNoAuth();
      }
    }
    throw error;
  }
};

// Wrapper for authenticated POST requests
export const post = async (path, payload, onNoAuth = null) => {
  let h = getAuthHeader(onNoAuth);
  try {
    const response = await axios.post(`${API_BASE}${path}`, payload, {
      headers: h,
      mode: "cors",
      credentials: "include",
    });
    return response;
  } catch (error) {
    if (error.response?.status === 401) {
      clearTokens();
      if (onNoAuth) {
        onNoAuth();
      }
    }
    throw error;
  }
};

export const isAuthenticated = (onNoAuth = null) => {
  let h = getAuthHeader(onNoAuth);
  return true;
};

export const logout = () => {
  clearTokens();
};

export const storeTokens = _tokens => {
  //expect _tokens to contain expiresIn: Seconds from now
  //get a timestamp in seconds from now reflecting the expiresIn
  let expiresAt = new Date().getTime() + _tokens.expiresIn * 1000;
  let tokens = JSON.stringify({ ..._tokens, expiresAt });
  localStorage.setItem("tokens", tokens);
};
