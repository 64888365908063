import useSession from "@/hooks/useSession";
import { logout, storeTokens } from "@/lib/axios_utils";
import {
  clearLandingInfo,
  getLandingInfo,
  normalizeToPhoneNumberFormat,
  storeLandingInfo,
  validateEmailAddress,
  validatePassword,
  validatePhoneNumber,
} from "@/lib/utils.js";
import { CheckCircle, Eye, EyeOff } from "lucide-react"; // Add this import
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoadingAnimation from "../LoadingAnimation";

//TODO - show password requirement indicators on why it's not meeting requirements
const pwRequirementtReasonFail = password => {
  let reasons = [];
  if (password.length < 8) reasons.push("At least 8 characters");
  if (!password.match(/[0-9]/)) reasons.push("At least 1 number");
  if (!password.match(/[!@#$%^&*]/))
    reasons.push("At least 1 special character (!@#$%^&*)");
  if (!password.match(/[A-Z]/)) reasons.push("At least 1 uppercase letter");
  if (!password.match(/[a-z]/)) reasons.push("At least 1 lowercase letter");
  return reasons;
};

const PasswordRequirement = ({ text, isMet }) => (
  <li
    className={`flex items-center gap-2 ${
      isMet ? "text-green-600" : "text-gray-500"
    }`}
  >
    {text}
    {isMet && <CheckCircle className='w-4 h-4' />}
  </li>
);

function SelfOnboard() {
  // const [firstname, setFirstname] = useState("");
  // const [lastname, setLastname] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState();
  const [phoneOrEmail, setPhoneOrEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [registrationSuccess, setRegistrationSuccess] = useState(false);

  const [showMFAEntry, setShowMFAEntry] = useState(false);
  const [mfaToken, setMfaToken] = useState("");
  const [isConfirmingMFA, setIsConfirmingMFA] = useState(false);

  const navigate = useNavigate();

  const [sh, setSh] = useState(getLandingInfo());

  // Check if user is already logged in
  const { isAuthenticated, isLoading: sessionLoading } = useSession();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // const validate_entries = (firstname, lastname, password, confirmPassword) => {
  const validate_entries = (password, confirmPassword) => {
    // if (!firstname || !lastname) return false;
    if (!validatePassword(password)) return false;
    if (password !== confirmPassword) return false;
    return true;
  };

  const validatePhoneOrEmail = phoneOrEmail => {
    //must be a phone number or an email address, and not empty.
    //phone # can be 10 digits, or 11 digits with a leading 1. Email address must have an @ symbol.
    if (validatePhoneNumber(phoneOrEmail)) {
      return "phone";
    }
    if (validateEmailAddress(phoneOrEmail)) {
      return "email";
    }
    return false;
  };

  const handleMFASubmit = async event => {
    event.preventDefault();
    setIsConfirmingMFA(true);
    setMessage("");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER}/api/pp2/onboard/mfa_confirm`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          mode: "cors",
          body: JSON.stringify({
            mfaToken,
            email: phoneOrEmail,
            password: password,
            //will be null if expired, we're passing this again so uv-api
            //can figure out if the email should be updated/and or avanced to consent verified
            sh: sh,
          }),
        }
      );

      const data = await response.json();

      if (data.error) {
        setMessage(data.error);
      } else if (data.tokens) {
        storeTokens(data.tokens);
        setRegistrationSuccess(true);
        setMessage("Registration successful! Redirecting...");
        // Optionally redirect user or show success state
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }
    } catch (error) {
      setMessage("Failed to verify MFA code. Please try again.");
    } finally {
      setIsConfirmingMFA(false);
    }
  };

  const handleRetrySignup = () => {
    setShowMFAEntry(false);
    setMfaToken("");
    setMessage("");
    setRegistrationSuccess(false);
  };

  const handleSubmit = event => {
    event.preventDefault();
    setIsSubmitting(true);
    setMessage("...");

    // if (!validate_entries(firstname, lastname, password, confirmPassword)) {
    if (!validate_entries(password, confirmPassword)) {
      setMessage(
        "Please check all fields. Make sure passwords match and meet all requirements."
      );
      setIsSubmitting(false);
      return;
    }

    let contactType = validatePhoneOrEmail(phoneOrEmail);
    if (!contactType) {
      setMessage("Invalid phone# or email address");
      setIsSubmitting(false);
      return;
    }

    fetch(
      `${process.env.REACT_APP_API_SERVER}/api/pp2/onboard/self_initiated`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify({
          // firstname,
          // lastname,
          contactType,
          contact: phoneOrEmail,
          password,
          sh: sh, //will be null if not coming from a landing page or expired
        }),
      }
    )
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          setMessage(data.error);
        } else {
          setShowMFAEntry(true);
          setMessage(
            data.message ||
              "Please enter the verification code sent to your email/phone"
          );
        }
        setIsSubmitting(false);
      })
      .catch(error => {
        console.error(error);
        setMessage(error.message);
        setIsSubmitting(false);
      });
  };

  const checkPasswordRequirement = password => ({
    length: password.length >= 8,
    number: /[0-9]/.test(password),
    special: /[!@#$%^&*]/.test(password),
    uppercase: /[A-Z]/.test(password),
    lowercase: /[a-z]/.test(password),
  });

  if (isSubmitting || isConfirmingMFA || sessionLoading) {
    return (
      <div className='w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-md'>
        <LoadingAnimation />
      </div>
    );
  }

  //lands here without having Hermes phase 1 landing and mmdd
  // if (!sh) {
  //   return (
  //     <div className='w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-md'>
  //       <h2 className='text-1xl font-bold mb-4'>Registration Unavailable</h2>
  //       <p className=''>
  //         Registration is currenly only available to Supercare Health patients
  //         that have been onboarded by a Supercare Health representative.
  //       </p>
  //     </div>
  //   );
  // }

  if (isAuthenticated) {
    return (
      <div className='w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-md'>
        <p>
          You are already logged in.
          <br />
          <Link to='/' className='text-blue-500 hover:underline'>
            Go to Patient Dashboard
          </Link>
          <br />
          or
          <br />
          <a onClick={e => logout()} className='text-blue-500 hover:underline'>
            Logout
          </a>
        </p>
      </div>
    );
  }

  if (registrationSuccess) {
    return (
      <div className='w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-md'>
        <h2 className='text-1xl font-bold mb-4'>Registration Successful!</h2>
        <p className='text-center'>Redirecting...</p>
      </div>
    );
  }

  if (showMFAEntry) {
    return (
      <div className='w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-md'>
        <h2 className='text-1xl font-bold mb-4'>Enter Verification Code</h2>
        <div className='mb-4'>
          <label
            htmlFor='mfaToken'
            className='block text-sm font-medium text-gray-600 mb-1'
          >
            Verification Code:
          </label>
          <input
            type='text'
            id='mfaToken'
            className='w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500'
            placeholder='Enter code'
            value={mfaToken}
            onChange={e => setMfaToken(e.target.value)}
          />
        </div>

        <button
          className='w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300 mb-2'
          onClick={handleMFASubmit}
        >
          Submit Code
        </button>

        {message && <p className='mt-4 text-center'>{message}</p>}

        <hr />
        <div className='mt-16'>
          <a
            onClick={handleRetrySignup}
            className='text-sm text-blue-500 hover:underline cursor-pointer'
          >
            Back to Registration Form
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className='w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-md'>
      <h2 className='text-2xl font-bold'>Registration</h2>
      <h2 className='text-1xl mb-4'>
        Registration is available only to Supercare Health patients.
      </h2>

      {/* <div className='mb-4'>
          <label
            htmlFor='firstname'
            className='block text-sm font-medium text-gray-600 mb-1'
          >
            First Name
          </label>
          <input
            type='text'
            id='month'
            autoComplete='off'
            name='fname_prevent_autofill'
            className='w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500'
            placeholder='Enter First Name'
            value={firstname}
            onChange={e => setFirstname(e.target.value)}
          />
        </div>
        <div className='mb-4'>
          <label
            htmlFor='last_name'
            className='block text-sm font-medium text-gray-600 mb-1'
          >
            Last Name
          </label>
          <input
            type='text'
            id='last_name'
            autoComplete='off'
            name='lname_prevent_autofill'
            className='w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500'
            placeholder='Enter Last Name'
            value={lastname}
            onChange={e => setLastname(e.target.value)}
          />
        </div> */}

      <form onSubmit={handleSubmit} className='space-y-4'>
        <div className='mb-4'>
          <label
            htmlFor='contact'
            className='block text-sm font-medium text-gray-600 mb-1'
          >
            Email Address on file for the Patient:
          </label>
          <input
            type='text'
            id='contact'
            autoComplete='off'
            name='contact_prevent_autofill'
            className='w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500'
            placeholder='Enter email address (this will be your username)'
            value={phoneOrEmail}
            onChange={e => setPhoneOrEmail(e.target.value)}
          />
        </div>

        <div className='mb-4'>
          <label
            htmlFor='password'
            className='block text-sm font-medium text-gray-600 mb-1'
          >
            Create Password
          </label>
          <div className='mt-1 text-xs mb-2'>
            <p className='text-gray-500 mb-1'>Password must contain:</p>
            <ul className='list-none pl-4'>
              <PasswordRequirement
                text='At least 8 characters'
                isMet={checkPasswordRequirement(password).length}
              />
              <PasswordRequirement
                text='At least 1 number'
                isMet={checkPasswordRequirement(password).number}
              />
              <PasswordRequirement
                text='At least 1 special character (!@#$%^&*)'
                isMet={checkPasswordRequirement(password).special}
              />
              <PasswordRequirement
                text='At least 1 uppercase letter'
                isMet={checkPasswordRequirement(password).uppercase}
              />
              <PasswordRequirement
                text='At least 1 lowercase letter'
                isMet={checkPasswordRequirement(password).lowercase}
              />
            </ul>
          </div>
          <div className='relative'>
            <input
              type={showPassword ? "text" : "password"}
              id='password'
              autoComplete='new-password'
              name='pwd_prevent_autofill'
              className='w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500'
              placeholder='Enter password'
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <button
              type='button'
              onClick={() => setShowPassword(!showPassword)}
              className='absolute right-3 top-1/2 transform -translate-y-1/2'
            >
              {showPassword ? (
                <EyeOff className='h-4 w-4 text-gray-500' />
              ) : (
                <Eye className='h-4 w-4 text-gray-500' />
              )}
            </button>
          </div>
        </div>

        <div className='mb-4'>
          <label
            htmlFor='confirm_password'
            className='block text-sm font-medium text-gray-600 mb-1'
          >
            Re-enter Password:
          </label>
          <div className='relative'>
            <input
              type={showConfirmPassword ? "text" : "password"}
              id='confirm_password'
              autoComplete='new-password'
              name='pwd_confirm_prevent_autofill'
              className='w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500'
              placeholder='Re-enter password'
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
            />
            <button
              type='button'
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              className='absolute right-3 top-1/2 transform -translate-y-1/2'
            >
              {showConfirmPassword ? (
                <EyeOff className='h-4 w-4 text-gray-500' />
              ) : (
                <Eye className='h-4 w-4 text-gray-500' />
              )}
            </button>
          </div>
        </div>

        {isSubmitting ? (
          <button className='w-full bg-gray-200 text-white py-2 rounded-md hover:bg-gray-100 transition duration-300'>
            ...validating your entries
          </button>
        ) : (
          <button
            className='w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-300'
            type='submit'
          >
            Submit
          </button>
        )}
        {message && (
          <p className='mt-4 bg-orange-200 p-4 rounded-sm text-xs text-red-600'>
            {message}
          </p>
        )}
      </form>

      <hr />
      <div className='mt-4'>
        <span className='text-sm text-gray-600'>Already registered? </span>
        <Link to='/login' className='text-sm text-blue-500 hover:underline'>
          Click here to Sign In
        </Link>
      </div>
    </div>
  );
}

export default SelfOnboard;
