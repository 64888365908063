import useSession from "@/hooks/useSession";
import {
  clearLandingInfo,
  getLandingInfo,
  storeLandingInfo,
} from "@/lib/utils.js";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingAnimation from "../LoadingAnimation";
import AOBRequestBlock from "../components/AOBRequestBlock";
import TimelinesWrapper from "../components/TimelinesWrapper";
import { validate_date } from "../lib/utils.js";

const CHECK_AOB_FREQUENCY = 5000;
const CHECK_AOB_MAX = 30;

function NotifLanding() {
  //obtain referral_code from the route parameter
  const { url_hash } = useParams();
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [message, setMessage] = useState("");
  const [isDoingLandingChecks, setIsDoingLandingChecks] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hashCodeValid, setHashCodeValid] = useState(true);
  // const [timelines, setTimelines] = useState([]);
  const [extMsg, setExtMsg] = useState(null);
  const [urlToAOB, setUrlToAOB] = useState(null);
  const [account, setAccount] = useState(null);
  const [aobStatusCheckCount, setAOBStatusCheckCount] = useState(0);
  const [aobStatusFilled, setAOBStatusFilled] = useState(false);

  const navigate = useNavigate();

  //upon loading the page, we have to first do an api call to the backend to check if the referal code is valid and not expired, then we can show the form to the user
  const hashCodeChecked = useRef(false);

  //this is only necessary during Phase 1 - so that subsequent refreshing on this route does not ask for MM DD again
  //so long as the referral code has not expired has and has been validated, and MMDD was already verified
  // const [hasAccessToken, setHasAccessToken] = useState(false);

  const [didJustVerifyMMDD, setDidJustVerifyMMDD] = useState(false);

  // Check if user is already logged in to patient portal (different from hermes order status session handing)
  const { isAuthenticated, isLoading: sessionLoading } = useSession({
    kind: "ext_message_hash",
    url_hash,
  });

  // const navigate = useNavigate();
  // const setAccessToken = jwt => {
  //   localStorage.setItem(
  //     process.env.REACT_LC_PREFIX,
  //     JSON.stringify({
  //       a: jwt,
  //       i: new Date().getTime(),
  //     })
  //   );
  //   setHasAccessToken(true);
  // };
  const onAOBUrlClicked = () => {
    //parse the url by "/" to get the jotform formId and prefilledUrlId
    let parts = urlToAOB.split("/");
    let formId = parts[parts.length - 3];
    let prefilledUrlId = parts[parts.length - 1];

    console.log({
      formId,
      prefilledUrlId,
    });

    //open the url in a new tab
    window.open(urlToAOB, "_blank");

    setTimeout(
      () => checkAOBStatus(formId, prefilledUrlId),
      CHECK_AOB_FREQUENCY
    );
  };

  useEffect(() => {
    if (hashCodeChecked.current) return;
    // let notif_rc_local = localStorage.getItem(LOCAL_STORAGE_KEY);
    checkhashCode();
  });

  // const clearLandingInfo = msg => {
  //   //force clear session and take user back to MMDD verification
  //   window.localStorage.removeItem(LOCAL_STORAGE_KEY);
  //   setMessage(msg);

  //   setTimeout(() => {
  //     window.location.reload();
  //   }, 3000);
  // };

  // const storeLandingInfo = sh => {
  //   console.log({ msg: "storing session in ", LOCAL_STORAGE_KEY, sh });
  //   window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(sh));
  // };

  // const getLandingInfo = () => {
  //   let sh = window.localStorage.getItem(LOCAL_STORAGE_KEY);
  //   if (sh === null) return null;
  //   return JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY));
  // };

  const checkhashCode = () => {
    // console.log("checking referral code");
    hashCodeChecked.current = true;
    let sh = getLandingInfo();
    let payload = { h: url_hash };
    if (sh) {
      payload.sh = sh;
    }
    fetch(`${process.env.REACT_APP_API_SERVER}/api/pp2/notif`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify(payload),
    })
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          throw data.error;
        }
        if (data.success === false) {
          throw data.error;
        }
        if (data.success === true) {
          setIsDoingLandingChecks(false);
          setHashCodeValid(true);

          if (data.extMsg) {
            setDidJustVerifyMMDD(true);
            setExtMsg(data.extMsg);
            setAccount(data.extMsg.account);
          }

          if (data.aob_prefilled_url) {
            setUrlToAOB(data.aob_prefilled_url);
          }
        }
      })
      .catch(error => {
        console.error(error);
        setMessage(error.message);
        setHashCodeValid(false);
        setIsDoingLandingChecks(false);
        //clear local storage referral code so that next attempt requires MMDD again
        // window.localStorage.removeItem(LOCAL_STORAGE_KEY);
        // clearLandingInfo();
      });
  };

  const handleSubmit = event => {
    event.preventDefault();
    setIsSubmitting(true);
    setMessage("");
    if (!validate_date(month, day)) {
      // set message to "Invalid date"
      setMessage("Invalid date");
      setIsSubmitting(false);
      return;
    }

    //convert month and day to mmdd format
    const mmdd = month.padStart(2, "0") + day.padStart(2, "0");

    /* 
      Make a cross domain fetch post to the bakcend (server is stored in env var REACT_APP_API_SERVER) at path '/api/pp2/login'
      with body { mmdd, referral_code }. 
      If error, set message to error message from server, if success, call setAccessToken() passing in access token from server
    */
    fetch(`${process.env.REACT_APP_API_SERVER}/api/pp2/notif`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify({ mmdd, h: url_hash }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          setMessage(data.error);
        } else {
          setMessage("");
          // setAccessToken(data.access_token);
          setDidJustVerifyMMDD(true);
          setExtMsg(data.extMsg);
          if (data.sh) {
            storeLandingInfo(data.sh);
          }

          if (data.aob_prefilled_url) {
            setUrlToAOB(data.aob_prefilled_url);
          }

          // remove local storage referral code so that refresh doesn't find the valid referral code
          // localStorage.removeItem(process.env.REACT_LC_PREFIX + "_rc");
          // setMessage("...signing you in...");
        }
        setIsSubmitting(false);
      })
      .catch(error => {
        console.error(error);
        setMessage(error.message);
        setIsSubmitting(false);
      });
  };

  //this is only for updating the UI to update the "Fill AOB" message
  //so we can acknowledege in the UI that the user has FILLED the AOB on Joform
  const checkAOBStatus = (jotformFormId, jotformPrefilledUrlId) => {
    if (aobStatusCheckCount >= CHECK_AOB_MAX) {
      return;
    }

    let payload = { account, jotformFormId, jotformPrefilledUrlId };
    let sh = getLandingInfo();
    if (sh) {
      payload.sh = sh;
    } else {
      return;
    }

    setAOBStatusCheckCount(aobStatusCheckCount + 1);
    fetch(
      `${process.env.REACT_APP_API_SERVER}/api/pp2/jotform_submissions/aob_status_check`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify(payload),
      }
    )
      .then(response => response.json())
      .then(data => {
        if (data.success === true) {
          //update the aob message
          setAOBStatusFilled(true);
          return;
        }

        if (data.error) {
          return; //do nothing
        }

        if (data.success === false) {
          //keep checking
          setTimeout(
            () => checkAOBStatus(jotformFormId, jotformPrefilledUrlId),
            CHECK_AOB_FREQUENCY
          );
          return;
        }
      })
      .catch(error => {
        console.error(error);
        return;
      });
  };

  const handleDownloadPOD = pod_sub_id => {
    let payload = { account };
    let sh = getLandingInfo();
    if (sh) {
      payload.sh = sh;
    } else {
      return clearLandingInfo(
        "Your session has expired. Please re-enter MMDD to continue.",
        setMessage
      );
    }

    fetch(`${process.env.REACT_APP_API_SERVER}/api/pp2/pod/${pod_sub_id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify(payload),
    })
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          return clearLandingInfo(
            "Your session has expired. Please re-enter MMDD to continue.",
            setMessage
          );
        }
        if (data.success === false) {
          throw data.error;
        }
        if (data.success === true) {
          //download the file
          window.open(data.url, "_blank");
        }
      })
      .catch(error => {
        console.error(error);
        // return clearlandinginfo(
        //   "your session has expired. please re-enter mmdd to continue."
        // );
      });
  };

  //for demo and qa only
  // const handleQAReset = () => {
  //   localStorage.removeItem(process.env.REACT_LC_PREFIX);
  //   localStorage.removeItem(process.env.REACT_LC_PREFIX + "_rc");
  //   setMonth("");
  //   setDay("");
  //   setMessage("");
  //   setIsSubmitting(false);
  //   setHasAccessToken(false);
  //   hashCodeChecked.current = false;
  // };

  //end demo and qa stuff

  if (isDoingLandingChecks || sessionLoading) {
    return (
      <div className='flex items-center justify-center min-h-screen'>
        <LoadingAnimation />
      </div>
    );
  }

  if (isAuthenticated) {
    setTimeout(() => {
      navigate("/notifications/fromlink/" + url_hash);
    });

    return (
      <div className='flex items-center justify-center min-h-screen'>
        <LoadingAnimation />
        ...you are already logged in, redirecting you to the logged in version
        of this notification page...
      </div>
    );
  }

  // if (!hashCodeChecked) {
  //   // checkhashCode();
  //   return (
  //     <div className="flex justify-center items-center ">
  //       <div className="bg-gray-200 p-8 rounded-lg shadow-md w-80">
  //         <p className="mt-2 text-center text-sm text-gray-600">
  //           ...checking referral code...
  //         </p>
  //       </div>
  //     </div>
  //   );
  // }

  if (!hashCodeValid) {
    return (
      <div className='w-full max-w-md min-w-[20vw] p-8 space-y-6 rounded-lg shadow-md'>
        <p className='mt-2 text-center text-sm text-gray-600'>
          Link is invalid or has expired.
          <br />
        </p>
        <hr />
        <p className='mt-2 text-center text-sm text-gray-600'>
          Please{" "}
          <a className='text-blue-500' href='/login'>
            Sign In
          </a>{" "}
          or{" "}
          <a className='text-blue-500' href='/onboard'>
            Register
          </a>{" "}
          to view your notifications.
        </p>
      </div>
    );
  }

  if (didJustVerifyMMDD) {
    return (
      <div className='w-full min-w-[20vw] p-8 space-y-6 rounded-lg shadow-md'>
        {urlToAOB && (
          <AOBRequestBlock
            onAOBUrlClicked={onAOBUrlClicked}
            aobStatusFilled={aobStatusFilled}
          />
        )}

        <TimelinesWrapper
          handleDownloadPOD={handleDownloadPOD}
          extMsg={extMsg}
        />
        {message && (
          <div className='bg-red-400 p-8 rounded-lg shadow-md w-100'>
            <p className='mt-2 text-sm text-black'>{message}</p>
          </div>
        )}

        <hr />
        {/* <div className='mt-16'>
          <p className='mt-2 text-center text-sm text-gray-600'>
            Please{" "}
            <a className='text-blue-500' href='/login'>
              Sign In
            </a>{" "}
            or{" "}
            <a className='text-blue-500' href='/onboard'>
              Register
            </a>{" "}
            to download your Proof of Delivery receipt.
          </p>
        </div> */}
      </div>
    );
  }

  return (
    <div className='w-full min-w-[20vw] p-8 space-y-6 bg-white rounded-lg shadow-md'>
      {/* <p className="mt-2 text-center text-sm text-gray-600">
          Your referral code is: {referral_code}
        </p> */}
      <h2 className='text-2xl font-bold'>Order Notification</h2>
      <h2 className='text-1xl mb-4'>
        Please enter the 2 digit month and 2 digit day of the patient's
        birthday:
      </h2>
      <div className='flex items-center mb-4'>
        <div className='w-1/2'>
          <label
            htmlFor='month'
            className='block text-sm font-medium text-gray-600 mb-1'
          >
            Month (MM)
          </label>
          <input
            autoComplete='off'
            type='text'
            id='month'
            className='w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500'
            placeholder=''
            value={month}
            maxLength={2}
            onChange={e => setMonth(e.target.value)}
          />
        </div>
        <div className='mx-2 self-center text-xl pt-6'>/</div>
        <div className='w-1/2'>
          <label
            htmlFor='day'
            className='block text-sm font-medium text-gray-600 mb-1'
          >
            Day (DD)
          </label>
          <input
            autoComplete='off'
            type='text'
            id='day'
            className='w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500'
            placeholder=''
            value={day}
            maxLength={2}
            onChange={e => setDay(e.target.value)}
          />
        </div>
      </div>
      {isSubmitting ? (
        <button className='w-full bg-gray-200 text-white py-2 rounded-md hover:bg-gray-100 transition duration-300'>
          ...attempting verification...
        </button>
      ) : (
        <button
          className='w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300'
          onClick={handleSubmit}
        >
          Submit
        </button>
      )}

      {message && (
        <p className='mt-4 bg-orange-200 p-4 rounded-sm text-xs text-red-600'>
          {message}
        </p>
      )}

      {/* {message && <p className='mt-4 text-center'>{message}</p>}
        <p className='font-thin text-xs mt-4 pt-8'>
          Disclaimer: By verifying the month and day of birth you are consenting
          for SuperCareHealth communicating with you via SMS/email.
        </p>
        <p className='text-xs mt-4 pt-8 font-bold'>
          FOR QA: Disclaimer above should be removed for this flow???
        </p> */}
    </div>
  );
}

export default NotifLanding;
