import { clearTokens, getTokens, post } from "@/lib/axios_utils";
import { useEffect, useRef, useState } from "react";

const useSession = extras => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState(null);
  const [cognitoUser, setCognitoUser] = useState(null);
  const [alerts, setAlerts] = useState([]);
  const [expireMessage, setExpireMessage] = useState("");
  const checkStarted = useRef(false);
  const [patientInfo, setPatientInfo] = useState(null);

  useEffect(() => {
    const checkAuth = async () => {
      const tokens = getTokens();
      const accessToken = tokens?.accessToken;

      if (!accessToken) {
        setIsAuthenticated(false);
        setIsLoading(false);
        return;
      }

      try {
        const response = await post("/pp2/auth_status", { extras });
        setCognitoUser(response.data.cognito_user);
        setAlerts(response.data.alerts);
        setIsAuthenticated(true);
        setPatientInfo(tokens.info);
        setError(null);
      } catch (err) {
        console.error(err);
        clearTokens();
        setIsAuthenticated(false);
        setError(err.message || "Session check failed");
      } finally {
        setIsLoading(false);
      }
    };

    if (!checkStarted.current) {
      checkStarted.current = true;
      checkAuth();
    }
  }, [extras]);

  return {
    isLoading,
    isAuthenticated,
    error,
    cognitoUser,
    alerts,
    patientInfo,
  };
};

export default useSession;
