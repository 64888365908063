import useSession from "@/hooks/useSession";
import {
  clearLandingInfo,
  getLandingInfo,
  storeLandingInfo,
  validate_date,
} from "@/lib/utils.js";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import LoadingAnimation from "../LoadingAnimation";

// const validate_date = (month, day) => {
//   //month must resolve to an integer between 1 and 12 inclusive, day must resolve to an integer between 1 and 31 inclusive
//   const parsedMonth = parseInt(month, 10);
//   const parsedDay = parseInt(day, 10);
//   if (isNaN(parsedMonth) || isNaN(parsedDay)) {
//     return false;
//   }

//   if (
//     parsedMonth >= 1 &&
//     parsedMonth <= 12 &&
//     parsedDay >= 1 &&
//     parsedDay <= 31
//   ) {
//     // Valid date
//     return true;
//   } else {
//     // Invalid date
//     return false;
//   }
// };

function ConsentLanding() {
  const navigate = useNavigate();
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [message, setMessage] = useState("");
  const [isDoingLandingChecks, setIsDoingLandingChecks] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inviteLinkValid, setInviteLinkValid] = useState(true);
  const [urlToAOB, setUrlToAOB] = useState("");

  //upon loading the page, we have to first do an api call to the backend to check if the referal code is valid and not expired, then we can show the form to the user
  const referralCodeChecked = useRef(false);

  //this is only necessary during Phase 1 - so that subsequent refreshing on this route does not ask for MM DD again
  //so long as the referral code has not expired has and has been validated, and MMDD was already verified
  const [hasAccessToken, setHasAccessToken] = useState(false);

  const [didJustVerifyMMDD, setDidJustVerifyMMDD] = useState(false);

  //obtain referral_code from the route parameter
  const { referral_code, mode } = useParams();
  // console.log({ referral_code, mode });

  const { isAuthenticated, isLoading: sessionLoading } = useSession({
    kind: "consent_landing",
    referral_code,
    mode,
  });

  useEffect(() => {
    if (referralCodeChecked.current) return;
    let rc_local = localStorage.getItem(process.env.REACT_LC_PREFIX + "_rc");
    checkReferralCode();
  }, []);

  const checkReferralCode = () => {
    console.log("checking referral code");
    fetch(
      `${process.env.REACT_APP_API_SERVER}/api/pp2/consent/validate_referral_code`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        mode: "cors",
        body: JSON.stringify({ referral_code, mode }),
      }
    )
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          setMessage("Invitation link is not valid.");
          setInviteLinkValid(false);
          setIsDoingLandingChecks(false);
          return;
        }

        //date when the referral code will expire
        let rc_expires = new Date();
        rc_expires.setSeconds(
          rc_expires.getSeconds() + data.seconds_to_rc_expire
        );
        console.log(
          "RC will expire at datetime:",
          new Date(rc_expires).toString()
        );

        //this is NOT the sh token, this is just the referral code check that
        //we store so that we don't have to keep re-validiting the referral code
        //prior to attempting to verify MMDD
        localStorage.setItem(
          process.env.REACT_LC_PREFIX + "_rc",
          JSON.stringify({
            rc: referral_code,
            rc_expires: rc_expires.getTime(),
          })
        );

        setIsDoingLandingChecks(false);
      })
      .catch(error => {
        console.error(error);
        setMessage(error.message);
        setIsDoingLandingChecks(false);
      });
  };

  const handleSubmit = event => {
    event.preventDefault();
    setIsSubmitting(true);
    setMessage("...");
    if (!validate_date(month, day)) {
      // set message to "Invalid date"
      setMessage("Invalid date");
      setIsSubmitting(false);
      return;
    }

    //convert month and day to mmdd format
    const mmdd = month.padStart(2, "0") + day.padStart(2, "0");

    fetch(`${process.env.REACT_APP_API_SERVER}/api/pp2/consent/validate_mmdd`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify({ mmdd, referral_code, mode }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          setMessage(data.error.message || data.error);
          if (data.error.code) {
            if (data.error.code === "ALREADY_REGISTERED") {
              clearLandingInfo(
                "Referral code has expired. Please request a new invitation link."
              );
            }
          }
        } else {
          setDidJustVerifyMMDD(true);
          // remove local storage referral code so that refresh doesn't find the valid referral code
          if (data.sh) {
            storeLandingInfo(data.sh);
          }

          //this is NOT the sh token, this is just the referral code check that
          //we store so that we don't have to keep re-validiting the referral code
          //prior to attempting to verify MMDD
          localStorage.removeItem(process.env.REACT_LC_PREFIX + "_rc");

          if (data.aob_prefilled_url) {
            setUrlToAOB(data.aob_prefilled_url);
          }
        }
        setIsSubmitting(false);
      })
      .catch(error => {
        console.error(error);
        setMessage(error.message);
        setIsSubmitting(false);
      });
  };

  //for demo and qa only
  // const handleQAReset = () => {
  //   localStorage.removeItem(process.env.REACT_LC_PREFIX);
  //   localStorage.removeItem(process.env.REACT_LC_PREFIX + "_rc");
  //   setMonth("");
  //   setDay("");
  //   setMessage("");
  //   setIsSubmitting(false);
  //   setHasAccessToken(false);
  //   referralCodeChecked.current = false;
  // };

  //end demo and qa stuff
  // return (
  //   <div className='flex items-center justify-center min-h-screen bg-gray-100'>
  //     <div className='w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-md'>
  //       <p className='mt-2 text-sm text-gray-600'>
  //         Thank you for verifying. You are now subscribed to SuperCare{" "}
  //         {"email".toUpperCase()} delivery notifications.
  //         {/* Please call SuperCare or reach us via chat at www.supercarehealth.com to unsubscribe. */}
  //         {/* <br /> */}
  //         {/* [Onboarding Milestone 20 reached - User verified MMDD] */}
  //       </p>
  //       <hr />
  //       <div className='mt-16'>
  //         <p className='mt-2 text-center text-sm text-gray-600'>
  //           Please{" "}
  //           <a className='text-blue-500' href='/login'>
  //             Sign In
  //           </a>{" "}
  //           or{" "}
  //           <a className='text-blue-500' href='/onboard'>
  //             Register
  //           </a>{" "}
  //           for access to our Patient Portal.
  //         </p>
  //       </div>
  //     </div>
  //   </div>
  // );

  if (isDoingLandingChecks) {
    return (
      <div className='w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-md'>
        <LoadingAnimation />
      </div>
    );
  }

  if (!referralCodeChecked) {
    // checkReferralCode();
    return (
      <div className='w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-md'>
        <p className='mt-2 text-center text-sm text-gray-600'>
          ...checking referral code...
        </p>
      </div>
    );
  }

  if (!inviteLinkValid) {
    return (
      <div className='w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-md'>
        <p className='mt-2 text-center text-sm text-gray-600'>
          Invitation link is invalid or has expired.
        </p>
        <hr />
        {/* <p className='mt-2 text-center text-sm text-gray-600'>
          Please{" "}
          <a className='text-blue-500' href='/login'>
            Sign In
          </a>{" "}
          or{" "}
          <a className='text-blue-500' href='/onboard'>
            Register
          </a>{" "}
          to view your notifications.
        </p> */}
      </div>
    );
    return (
      <div className='w-full max-w-md min-w-[50vw] p-8 space-y-6 bg-white rounded-lg shadow-md'>
        <div className='bg-gray-200 p-8 rounded-lg shadow-md w-80'>
          <p className='mt-2 text-center text-sm text-gray-600'>
            Invitation link is invalid or has expired.
          </p>
        </div>
      </div>
    );
  }

  if (didJustVerifyMMDD) {
    setTimeout(() => {
      navigate("/onboard");
      return;
    }, 2000);
    return (
      <div className='w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-md'>
        <p className='mt-2 text-sm text-gray-600'>
          Thank you for verifying. You are now subscribed to SuperCare{" "}
          {mode.toUpperCase()} delivery notifications.
          {/* Please call SuperCare or reach us via chat at www.supercarehealth.com to unsubscribe. */}
          {/* <br /> */}
          {/* [Onboarding Milestone 20 reached - User verified MMDD] */}
        </p>

        <hr />
        <div className='mt-16'>
          <p className='mt-2 text-center text-sm text-gray-600'>
            <a className='text-blue-500' href='/login'>
              Sign in
            </a>{" "}
            or{" "}
            <a className='text-blue-500' href='/onboard'>
              Register
            </a>{" "}
            to access your patient portal.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className='w-full max-w-md p-8 space-y-6 bg-white rounded-lg shadow-md'>
      {/* <p className="mt-2 text-center text-sm text-gray-600">
          Your referral code is: {referral_code}
        </p> */}
      <h2 className='text-1xl mb-4'>
        Please enter the 2 digit month and 2 digit day of the patient's
        birthday:
      </h2>
      <div className='flex mb-4'>
        <div className='w-1/2'>
          <label
            htmlFor='month'
            className='block text-sm font-medium text-gray-600 mb-1'
          >
            Month (MM)
          </label>
          <input
            autoComplete='off'
            type='text'
            id='month'
            className='w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500'
            placeholder=''
            value={month}
            maxLength={2}
            onChange={e => setMonth(e.target.value)}
          />
        </div>
        <div className='mx-2 self-center'>/</div>
        <div className='w-1/2'>
          <label
            htmlFor='day'
            className='block text-sm font-medium text-gray-600 mb-1'
          >
            Day (DD)
          </label>
          <input
            autoComplete='off'
            type='text'
            id='day'
            className='w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500'
            placeholder=''
            value={day}
            maxLength={2}
            onChange={e => setDay(e.target.value)}
          />
        </div>
      </div>
      {isSubmitting ? (
        <button className='w-full bg-gray-200 text-white py-2 rounded-md hover:bg-gray-100 transition duration-300'>
          ...attempting verification...
        </button>
      ) : (
        <button
          className='w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-300'
          onClick={handleSubmit}
        >
          Submit
        </button>
      )}

      {message && (
        <p className='mt-4 bg-orange-200 p-4 rounded-sm text-xs text-red-600'>
          {message}
        </p>
      )}
      <p className='font-thin text-xs mt-4 pt-8'>
        Disclaimer: By verifying the month and day of birth you are consenting
        for SuperCareHealth communicating with you via SMS/email.
      </p>
    </div>
  );
}

export default ConsentLanding;
