import { storeTokens } from "@/lib/axios_utils";
import { validateEmailAddress, validatePassword } from "@/lib/utils";
import { CheckCircle, Eye, EyeOff } from "lucide-react";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoadingAnimation from "../LoadingAnimation";

const PasswordRequirement = ({ text, isMet }) => (
  <li
    className={`flex items-center gap-2 ${
      isMet ? "text-green-600" : "text-gray-500"
    }`}
  >
    {text}
    {isMet && <CheckCircle className='w-4 h-4' />}
  </li>
);

function ForgetPassword() {
  const [email, setEmail] = useState("");
  const [showMFAEntry, setShowMFAEntry] = useState(false);
  const [mfaToken, setMfaToken] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const checkPasswordRequirement = password => ({
    length: password.length >= 8,
    number: /[0-9]/.test(password),
    special: /[!@#$%^&*]/.test(password),
    uppercase: /[A-Z]/.test(password),
    lowercase: /[a-z]/.test(password),
  });

  const handleSubmit = async event => {
    event.preventDefault();
    setIsSubmitting(true);
    setMessage("");

    if (!validateEmailAddress(email)) {
      setMessage("Please enter a valid email address");
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER}/api/pp2/fp`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      const data = await response.json();

      if (data.error) {
        setMessage(data.error);
      } else if (data.success) {
        setShowMFAEntry(true);
        setMessage("Please check your email for the reset code.");
      }
    } catch (error) {
      setMessage("An error occurred. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleMFASubmit = async event => {
    event.preventDefault();
    setIsSubmitting(true);
    setMessage("");

    if (!validatePassword(password)) {
      setMessage("Password does not meet requirements");
      setIsSubmitting(false);
      return;
    }

    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_SERVER}/api/pp2/fp_confirm`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
            mfaToken,
            newPassword: password,
          }),
        }
      );

      const data = await response.json();

      if (data.error) {
        setMessage(data.error);
      } else if (data.tokens) {
        storeTokens(data.tokens);
        setMessage("Password reset successful! Signing you in...");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }
    } catch (error) {
      setIsSubmitting(false);
      setMessage("An error occurred. Please try again.");
    }
  };

  if (isSubmitting) {
    return (
      <div className='w-full max-w-md min-w-[20vw] p-8 space-y-6 bg-white rounded-lg shadow-md'>
        <LoadingAnimation />
      </div>
    );
  }

  return (
    <div className='bg-brand-navy w-full max-w-md min-w-[20vw] p-8 space-y-6 bg-white rounded-lg shadow-md'>
      <h2 className='text-2xl font-bold'>Forgot Password</h2>
      <p className='text-sm text-gray-600 mt-2'>
        A verification code will be sent to your email address to reset your
        password.
      </p>

      {!showMFAEntry ? (
        // Initial email form
        <form onSubmit={handleSubmit} className='space-y-4'>
          <div>
            <label
              htmlFor='email'
              className='block text-sm font-medium text-gray-700'
            >
              Email Address
            </label>
            <input
              type='email'
              id='email'
              className='mt-1 block w-full rounded-md border border-gray-300 px-3 py-2'
              value={email}
              onChange={e => setEmail(e.target.value)}
              required
            />
          </div>
          <button
            type='submit'
            className='w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-300'
            disabled={isSubmitting}
          >
            Send Reset Code
          </button>
        </form>
      ) : (
        // MFA and new password form
        <form onSubmit={handleMFASubmit} className='space-y-4'>
          <div>
            <label
              htmlFor='mfaToken'
              className='block text-sm font-medium text-gray-700'
            >
              Verification Code
            </label>
            <input
              type='text'
              id='mfaToken'
              className='mt-1 block w-full rounded-md border border-gray-300 px-3 py-2'
              value={mfaToken}
              onChange={e => setMfaToken(e.target.value)}
              required
            />
          </div>

          <div>
            <label
              htmlFor='password'
              className='block text-sm font-medium text-gray-700'
            >
              New Password
            </label>
            <div className='mt-1 text-xs mb-2'>
              <p className='text-gray-500 mb-1'>Password must contain:</p>
              <ul className='list-none pl-4'>
                <PasswordRequirement
                  text='At least 8 characters'
                  isMet={checkPasswordRequirement(password).length}
                />
                <PasswordRequirement
                  text='At least 1 number'
                  isMet={checkPasswordRequirement(password).number}
                />
                <PasswordRequirement
                  text='At least 1 special character (!@#$%^&*)'
                  isMet={checkPasswordRequirement(password).special}
                />
                <PasswordRequirement
                  text='At least 1 uppercase letter'
                  isMet={checkPasswordRequirement(password).uppercase}
                />
                <PasswordRequirement
                  text='At least 1 lowercase letter'
                  isMet={checkPasswordRequirement(password).lowercase}
                />
              </ul>
            </div>
            <div className='relative'>
              <input
                type={showPassword ? "text" : "password"}
                id='password'
                className='mt-1 block w-full rounded-md border border-gray-300 px-3 py-2'
                value={password}
                onChange={e => setPassword(e.target.value)}
                required
              />
              <button
                type='button'
                onClick={() => setShowPassword(!showPassword)}
                className='absolute right-3 top-1/2 transform -translate-y-1/2'
              >
                {showPassword ? (
                  <EyeOff className='h-4 w-4 text-gray-500' />
                ) : (
                  <Eye className='h-4 w-4 text-gray-500' />
                )}
              </button>
            </div>
          </div>

          <div>
            <label
              htmlFor='confirmPassword'
              className='block text-sm font-medium text-gray-700'
            >
              Confirm New Password
            </label>
            <div className='relative'>
              <input
                type={showConfirmPassword ? "text" : "password"}
                id='confirmPassword'
                className='mt-1 block w-full rounded-md border border-gray-300 px-3 py-2'
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
                required
              />
              <button
                type='button'
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                className='absolute right-3 top-1/2 transform -translate-y-1/2'
              >
                {showConfirmPassword ? (
                  <EyeOff className='h-4 w-4 text-gray-500' />
                ) : (
                  <Eye className='h-4 w-4 text-gray-500' />
                )}
              </button>
            </div>
          </div>

          <button
            type='submit'
            className='w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-300'
            disabled={isSubmitting}
          >
            Reset Password
          </button>
        </form>
      )}

      {message && (
        <p className='mt-4 bg-orange-200 p-4 rounded-sm text-xs text-red-600'>
          {message}
        </p>
      )}

      <hr />
      <div className='mt-4'>
        <Link to='/login' className='text-sm text-blue-500 hover:underline'>
          Back to Login
        </Link>
      </div>
    </div>
  );
}

export default ForgetPassword;
