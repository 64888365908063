import React from "react";

const LogoWrapper = ({ children }) => {
  return (
    <div className='flex flex-col items-center w-full'>
      <div className='mb-2 sm:mb-8'>
        <img src='/logo.svg' alt='SuperCare Health' className='h-12' />
      </div>
      <div className='flex items-center justify-center min-h-[82vh] bg-brand-white'>
        {children}
      </div>
    </div>
  );
};

export default LogoWrapper;
