import React, { createContext, useContext, useState } from "react";

const CacheContext = createContext(null);

export function CacheProvider({ children, ttlMinutes = 5 }) {
  const [cache, setCache] = useState(new Map());

  const setCacheValue = (key, value) => {
    cache.set(key, {
      value,
      timestamp: Date.now(),
      ttl: ttlMinutes * 60 * 1000,
    });
    setCache(new Map(cache));
  };

  const getCacheValue = key => {
    const item = cache.get(key);
    if (!item) return null;

    const now = Date.now();
    if (now - item.timestamp > item.ttl) {
      cache.delete(key);
      setCache(new Map(cache));
      return null;
    }

    return item.value;
  };

  return (
    <CacheContext.Provider value={{ setCacheValue, getCacheValue }}>
      {children}
    </CacheContext.Provider>
  );
}

export const useCache = () => {
  const context = useContext(CacheContext);
  if (!context) {
    throw new Error("useCache must be used within a CacheProvider");
  }
  return context;
};
