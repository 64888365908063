import LoadingAnimation from "@/LoadingAnimation";
import React from "react";

export const SessionLoader = () => {
  return (
    <div className='w-full px-2 sm:p-8 py-4 space-y-6 bg-white'>
      <div className='max-w-7xl mx-auto px-4 sm:min-w-[800px]'>
        <LoadingAnimation />
      </div>
    </div>
  );
};

export const EmptySection = ({ message }) => {
  return (
    <div className='w-full flex flex-col items-start p-4 bg-brand-ligth-blue text-brand-muted'>
      <p className='text-sm text-brand-black'>📄 {message}</p>
    </div>
  );
};
